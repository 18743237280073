<template>
  <div class="list-user">
    <sortable-list
      :has-pagination="pagination"
      :items="filter"
      :parameters="listParameters"
      class="page-list"
      default-sort="firstname"
      link="/dashboard/setting/users/view/"
      selectable="multiple"
      @selected="onSelect"
      @item-click="itemClick"
    />
  </div>
</template>

<script>
import Fuse from "fuse.js";
import { mapActions, mapGetters } from "vuex";
import { LIST_USERS } from "@/store/modules/users/users.actions";

export default {
  name: "ListUsers",

  props: {
    pagination: Boolean,
    query: { type: String, default: "" },
    selectedList: { type: Array, default: () => [] },
  },

  data() {
    return {
      confirmationContext: "trash",
      isConfirmModalOpen: false,
      internalList: [],
    };
  },

  computed: {
    ...mapGetters(["isJEPA"]),
    ...mapGetters("users", [LIST_USERS]),

    listParameters() {
      return [
        {
          name: this.$t("list-user.fullName"),
          key: "fullName",
          class: this.isJEPA
            ? "list-user__fullName alt-item"
            : "list-user__fullName primary-item",
        },
        {
          name: this.$t("form.email"),
          key: "email",
        },
        {
          name: this.$t("form.status"),
          key: "scope",
          format: this.$$filters.formatScope,
        },
        {
          name: "",
          isIcon: true,
          iconFormat: () => "delete",
          iconClass: () => "",
          clickable: true,
          width: "50px",
        },
      ];
    },

    filter() {
      if (this.query === "") {
        return this.internalList;
      }

      const fuse = new Fuse(this.internalList, {
        shouldSort: true,
        threshold: 0.4,
        distance: 100,
        keys: ["firstname", "lastname", "email"],
      });

      return fuse.search(this.query).map(item => item.item);
    },

    confirmModalText() {
      const isSingle = this.selectedList.length < 2;

      return {
        header: isSingle
          ? this.$t("list-user.permanently-delete-modal-title-single")
          : this.$t("list-user.permanently-delete-modal-title-plural"),
        body: items => {
          return isSingle
            ? this.$t("list-user.permanently-delete-modal-body-single", {
                count: items.length,
              })
            : this.$t("list-user.permanently-delete-modal-body-plural", {
                fullName: items[0].fullName,
              });
        },
        question: this.$t("list-user.modal-confirm-cta"),
      };
    },
  },

  watch: {
    [LIST_USERS]() {
      this.internalList = [...this[LIST_USERS]];
    },
  },

  async mounted() {
    this.fetchList(LIST_USERS);
  },
  methods: {
    ...mapActions("users", ["fetchList"]),

    onSelect(items) {
      this.$emit("change-list", items);
    },

    itemClick({ item }) {
      if (this.selectedList.length <= 1) {
        this.$emit("change-list", [item]);
      }

      this.$emit("change-action", "trash");
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";
.list-user {
  .page-header{
    margin-bottom: toRem(15);
    @include for-lg{
      margin-bottom: toRem(30);
    }
  }
  .list-user-content {
    .md-title {
      display: none;
    }
  }
}
</style>
